import styled from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { CustomLink } from '@/components/CustomLink/CustomLink';

export const TabBarBox = styled.div<{
  $isVisible: boolean;
}>`
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  transition: 0.3s ease-in-out;
  justify-content: space-between;
  align-items: flex-end;
  padding: ${({ theme }) =>
    theme.isWebView ? '0 15px env(safe-area-inset-bottom)' : '0 15px'};
  height: ${({ theme }) => `${theme.tabBarHeight}px`};
  border-top: 1px solid ${({ theme }) => theme.palette.primary.border};
  background-color: ${({ theme }) => theme.palette.primary.background};
  transform: ${({ $isVisible }) =>
    $isVisible ? 'translateY(0)' : 'translateY(145%)'};
`;

export const TabBarBoxForSize = styled.div`
  height: ${({ theme }) => `${theme.tabBarHeight}px`};
  margin-top: auto;
`;

export const TabBarLink = styled(CustomLink)<{ $isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-15px);
  gap: 5px;
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
  width: ${({ theme }) => (theme.isWebView ? '60px' : 'auto')};
`;

export const TabBarLinkIcon = styled.div<{
  $iconName: string;
  $side: number;
  $isActive: boolean;
}>`
  position: relative;
  background-image: ${({ $iconName, $isActive }) =>
    `url('/images/${$iconName}${$isActive ? '-active' : ''}.svg')`};
  width: ${({ $side }) => `${$side}px`};
  height: ${({ $side }) => `${$side}px`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const TabBarUnreadIcon = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.primary.background};
  background-color: ${({ theme }) => theme.palette.brand.main};
`;

export const TabBarLinkText = styled(Typography.Tab)<{ $isActive?: boolean }>`
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.palette.brand.main : theme.palette.secondary.labels};
`;
